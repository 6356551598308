<template>
  <div class="container">
    <div class="text-center  mt-5">
      <a href="#" @click="toHome()">
        <img src="../assets/logo.png" class="img-fluid" alt="Responsive image">
      </a>
    </div>
    <h1 class="text-center text-muted">{{$t("message.Retrieve_Password")}}</h1>

    <!-- <form @submit="forgetPswUsrName(usrname)">
            <div class="form-group">
                <label for="usrnamel">{{$t("message.UserName")}}:</label>
                <input type="text" class="form-control" placeholder="Enter usrname" v-model="usrname">
            </div>
    
            <button type="submit" class="btn btn-outline-dark btn-lg btn-block">{{$t("message.Next_Step")}}</button>
            <small class="form-text text-danger" :class="{'d-none':rsl_next}" v-if="lan=='cn'">{{ foget_msg}}</small>
            <small class="form-text text-danger" :class="{'d-none':rsl_next}" v-if="lan=='en'">{{ foget_msg_en}}</small>
            <span v-b-modal.modal-2><p class="text-muted text-right">{{ $t("message.Register") }}</p></span>
    </form>-->
    <b-card border-variant="light">
      <b-form>
        <b-form-group :label="$t('message.UserName')" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="usrname"
            required
            :placeholder="$t('message.Please_Enter_UsrName')"
          ></b-form-input>
        </b-form-group>
        <div class="text-center">
          <b-button
            type="button"
            variant="outline-dark"
            @click="forgetPswUsrName(usrname)"
          >{{$t("message.Next_Step")}}</b-button>
        </div>
        <small
          class="form-text text-danger"
          :class="{'d-none':rsl_next}"
          v-if="lan=='cn'"
        >{{ foget_msg}}</small>
        <small
          class="form-text text-danger"
          :class="{'d-none':rsl_next}"
          v-if="lan=='en'"
        >{{ foget_msg_en}}</small>
        <!-- <span class="text-muted text-right" @click=""><p class="text-right mt-3">{{$t("message.Register")}}</p>  </span> -->
        <span v-b-modal.modal-2>
          <p class="text-muted text-right">{{ $t("message.Register") }}</p>
        </span>
      </b-form>
    </b-card>
    <b-modal id="modal-2" ref="modal-2" :title="$t('message.Register')" hide-footer>
      <form>
        <div class="form-group">
          <label for="exampleInputaUsrName">{{ $t("message.Username_Email_Phone") }}</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputaUsrName"
            aria-describedby="emailHelp"
            placeholder="Enter username"
            v-model="reqUsrName"
            required
          >
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">{{ $t("message.Password") }}</label>
          <input
            type="password"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="Password"
            v-model="reqPsw"
            required
          >
        </div>
        <div class="form-group">
          <label for="reqemail">{{ $t("message.email") }}</label>
          <input
            type="email"
            class="form-control"
            id="reqemail"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            v-model="reqEmail"
            required
          >
        </div>
        <button
          type="button"
          class="btn btn-primary"
          @click="req(reqUsrName, reqPsw,reqEmail)"
        >{{ $t("message.Sign_Up") }}</button>
        <small
          class="form-text text-danger"
          :class="{'d-none':rsl}"
          v-if="lan=='en'"
        >{{ reg_msg_en }}</small>
        <small
          class="form-text text-danger"
          :class="{'d-none':rsl}"
          v-if="lan=='cn'"
        >{{reg_msg_cn }}</small>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ForgetPsw",
  data() {
    return {
      reqUsrName: "",
      reqPsw: "",
      reqEmail: "",
      rsl: true,
      reg_msg_en: "",
      reg_msg_cn: "",
      rsl_next: true,
      usrname: "",
      foget_msg: "",
      foget_msg_en: ""
    };
  },
  computed: {
    ...mapGetters(["lan"])
  },
  methods: {
    ...mapActions({
      req(dispatch, reqUsrName, reqPsw, reqEmail) {
        if (reqUsrName === "" || reqPsw === "" || reqEmail === "") {
          return;
        }
        dispatch("req", { reqUsrName, reqPsw, reqEmail }).then(data => {
          this.rsl = data.result;
          if (data.result) {
            //  this.$router.go(0);
            this.toHome();
            this.hideModal2();
          } else {
            this.reg_msg_en = data.message_en;
            this.reg_msg_cn = data.message;
          }
        });
      },
      forgetPswUsrName(dispatch, username) {
        dispatch("forgetPswUsrName", { username }).then(data => {
          this.rsl_next = data.result;
          if (data.result) {
            this.$router.push({
              name: "enForgetPswEmail",
              params: { usrnm: username }
            });
          } else {
            this.foget_msg_en = data.message_en;
            this.foget_msg = data.message;
          }
        });
      }
    }),
    hideModal2() {
      this.$refs["modal-2"].hide();
    },
    toHome() {
      this.$router.push({
        name: "enhome"
      });
    }
  }
};
</script>
